import React from 'react';
import RequestError from '../../pages/api-documentation/request-error';

const ApiDocumentationGetIntegrations = () => {
  return (
    <div>
      <h4 className="info-title">To get integrations use this get API.</h4>
      <h5>HTTP Request</h5>
      <pre className="pre">
        <p>
          <span className="btn-pre">GET</span>{' '}
          https://api.hexowatch.com/v2/app/services/v1/integrations?key=&#123;YOUR_API_KEY&#125;
        </p>
      </pre>
      <h5>Parameters</h5>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">YOUR_API_KEY</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">
                {' '}
                to get YOUR_API_KEY visit hexowatch{' '}
                <a className="info-title" href="https://dash.hexowatch.com/hexowatch/settings" no-index>
                  settings{' '}
                </a>
                webhook section and copy your key
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <h5>Response Body</h5>
      <p>
        if response is <strong className="info-title"> successful </strong>
        you will get
      </p>

      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": false,</p>
        <p className="mgl-25">"result": [</p>
        <p className="mgl-50">{'{'}</p>
        <p className="mgl-75">"id": 110,</p>
        <p className="mgl-75">"type": "slack",</p>
        <p className="mgl-75">"data": "@example.example"</p>
        <p className="mgl-50">{'},'}</p>
        <p className="mgl-50">{'{'}</p>
        <p className="mgl-75">"id": 111,</p>
        <p className="mgl-75">"type": "telegram",</p>
        <p className="mgl-75">"data": "123456789"</p>
        <p className="mgl-50">{'},'}</p>
        <p className="mgl-50">{'{'}</p>
        <p className="mgl-75">"id": 112,</p>
        <p className="mgl-75">"type": "discord",</p>
        <p className="mgl-75">"data": "112233445566778899"</p>
        <p className="mgl-50">{'},'}</p>
        <p className="mgl-50">{'{'}</p>
        <p className="mgl-75">"id": 113,</p>
        <p className="mgl-75">"type": "email",</p>
        <p className="mgl-75">"data": "example@example.com"</p>
        <p className="mgl-50">{'},'}</p>
        <p className="mgl-25">]</p>
        <p>{'}'}</p>
      </pre>

      <RequestError />
    </div>
  );
};

export default ApiDocumentationGetIntegrations;
