import React, {FC} from 'react';

import Layout from '../../components/layout/layout';
import {PageHero} from '../../components/page-hero';
import Section from '../../components/section';

import RequestError from './request-error';
import PostRequestForTools from '../../components/post-request-for-tools';
import {toolArray, toolArrayType} from '../../components/post-request-for-tools/tool-data-array';
import ApiDocumentationLogsSanResults from '../../components/api-documentation-logs-scan-results';
import './styles.scss';
import ApiDocumentationGetIntegrations from '../../components/api-documentation-get-integrations';

const ApiDocumentation: FC = () => {
  return (
    <Layout>
      <PageHero title="API Documentation" subtitle="This page provides API documentation for monitoring tools" />
      <Section>
        <div className="col-12 d-block mb_20 api-doc" style={{maxWidth: '1200px', width: '100%'}}>
          <ApiDocumentationGetIntegrations />
          {toolArray.map((el: toolArrayType) => (
            <PostRequestForTools key={el.toolShortName} toolObject={el} />
          ))}

          <h4 className="info-title mt-4">To get all your monitored URLs ids use this get API.</h4>
          <h5>HTTP Request</h5>
          <pre className="pre">
            <p>
              <span className="btn-pre">GET</span>{' '}
              https://api.hexowatch.com/v2/app/services/v1/monitored_urls?key=&#123;YOUR_API_KEY&#125;
            </p>
          </pre>
          <h5>Parameters</h5>
          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">YOUR_API_KEY</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">
                    {' '}
                    to get YOUR_API_KEY visit hexowatch{' '}
                    <a className="info-title" href="https://dash.hexowatch.com/hexowatch/settings" no-index>
                      settings{' '}
                    </a>
                    webhook section and copy your key
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <h5>Response Body</h5>
          <p>
            if response is <strong className="info-title"> successful </strong>
            you will get
          </p>
          <div className="json-title-block">
            <span>JSON representation</span>
          </div>
          <pre className="pre">
            <p>{'{'}</p>
            <p className="mgl-25">"error": false,</p>
            <p className="mgl-25">"monitored_urls": [</p>
            <p className="mgl-50">
              {' '}
              {'{'} MonitoredUrls {'}'}
            </p>
            <p className="mgl-25">]</p>
            <p>{'}'}</p>
          </pre>

          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">error</td>
                <td>
                  <p className="bold-text">boolean</p>
                  <p className="normal-text">if the error returns true, it means that an error has occurred</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">monitored_urls []</td>
                <td>
                  <p className="bold-text">
                    object(<span className="normal-text">monitored URLs</span>)
                  </p>
                  <p className="normal-text">monitored URLs is a list of urls in object representation</p>
                </td>
              </tr>
            </tbody>
          </table>

          <h5>monitored URLs</h5>
          <p>monitored URLs object structure</p>
          <div className="json-title-block">
            <span>JSON representation</span>
          </div>
          <pre className="pre">
            <p>MonitoredUrls = {'{'}</p>
            <p className="mgl-25">"id": Number,</p>
            <p className="mgl-25">"address": String,</p>
            <p className="mgl-25">"name": String,</p>
            <p className="mgl-25">"active": Boolean,</p>
            <p>{'}'}</p>
          </pre>

          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">id</td>
                <td>
                  <p className="bold-text">number</p>
                  <p className="normal-text">monitoring id</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">address</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">URL of the monitoring</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">name</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">name of the monitoring</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">active</td>
                <td>
                  <p className="bold-text">boolean</p>
                  <p className="normal-text">if the monitoring is active</p>
                </td>
              </tr>
            </tbody>
          </table>

          <h5>Example</h5>

          <pre className="pre">
            <p>{'{'}</p>
            <p className="mgl-25">"error": false,</p>
            <p className="mgl-25">"monitored_urls": {'['}</p>
            <p className="mgl-50">{'{'}</p>
            <p className="mgl-75">"id": 1234,</p>
            <p className="mgl-75">"address": "hexometer.com",</p>
            <p className="mgl-75">"name": "my_url",</p>
            <p className="mgl-75">"active": true,</p>
            <p className="mgl-50">{'},'}</p>
            <p className="mgl-50">{'{'}</p>
            <p className="mgl-75">"id": 2321,</p>
            <p className="mgl-75">"address": "hexometer.com",</p>
            <p className="mgl-75">"name": "my_2_url",</p>
            <p className="mgl-75">"active": false,</p>
            <p className="mgl-50">{'}'}</p>
            <p className="mgl-25">{']'}</p>
            <p>{'}'}</p>
          </pre>

          <RequestError />

          <h4 className="info-title">To pause, resume or check now your monitorings use the following PATCH request</h4>
          <h5>HTTP Request</h5>

          <pre className="pre">
            <p>
              <span className="btn-pre">PATCH</span>{' '}
              https://api.hexowatch.com/v2/app/services/v1/action?key=&#123;YOUR_API_KEY&#125;
            </p>
          </pre>
          <h5>Parameters</h5>
          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">YOUR_API_KEY</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">
                    {' '}
                    to get YOUR_API_KEY visit hexowatch{' '}
                    <a className="info-title" href="https://dash.hexowatch.com/hexowatch/settings" no-index>
                      settings{' '}
                    </a>
                    webhook section and copy your key
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Request Body</h5>
          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">action</td>
                <td>
                  <p className="bold-text">
                    enum(<span className="normal-text">action</span>)
                  </p>
                  <p className="normal-text">
                    you should provide type of the action and monitoring ids that you want to perform actions on if you
                    pass monitoring_ids field as null action will be performed on each of your monitorings (you should
                    have got the monitoring ids in the previous GET request)
                  </p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">monitoring_ids</td>
                <td>
                  <p className="bold-text">
                    array(<span className="normal-text">number</span>) or null
                  </p>
                  <p className="normal-text">
                    you should provide type of the action and monitoring ids that you want to perform actions on if you
                    pass monitoring_ids field as null action will be performed on each of your monitorings (you should
                    have got the monitoring ids in the previous GET request)
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Action</h5>
          <table>
            <thead>
              <tr>
                <th>Enum</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">pause</td>
                <td>pause will stop monitor URL</td>
              </tr>
              <tr>
                <td className="td-fill">resume</td>
                <td>resume will start monitor URL</td>
              </tr>
              <tr>
                <td className="td-fill">check_now</td>
                <td>check_now will monitor right now</td>
              </tr>
            </tbody>
          </table>
          <h5>Example</h5>
          <pre className="pre">
            <p>{'{'}</p>
            <p className="mgl-25">"action": "check_now",</p>
            <p className="mgl-25">"monitoring_ids": [1234, 2321]</p>
            <p>{'}'}</p>
          </pre>
          <RequestError />

          {/* -------------------------------------------------- */}

          <ApiDocumentationLogsSanResults />
        </div>
      </Section>
    </Layout>
  );
};

export default ApiDocumentation;
