import React, {FC} from 'react';
import {Link} from 'gatsby';
import {toolArrayType} from './tool-data-array';

interface AppProps {
  toolObject: toolArrayType;
}

const PostRequestForTools: FC<AppProps> = ({toolObject}) => {
  const toolShortName = toolObject && toolObject.toolShortName;

  return (
    <>
      <h4 className="my-4 info-title">To create {toolObject && toolObject.toolFullName} use this POST API.</h4>
      <h5>HTTP Request</h5>
      <pre className="pre">
        <p>
          <span className="btn-pre">POST</span>{' '}
          https://api.hexowatch.com/v2/app/services/v2/monitor?key=&#123;YOUR_API_KEY&#125;
        </p>
      </pre>
      <h5>Parameters</h5>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">YOUR_API_KEY</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">
                {' '}
                to get YOUR_API_KEY visit hexowatch{' '}
                <a className="info-title" href="https://dash.hexowatch.com/hexowatch/settings" no-index>
                  settings{' '}
                </a>
                webhook section and copy your key
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <h5>Request Body</h5>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {toolObject &&
            toolObject.requestBodyFields.map((el: any) => (
              <tr key={el.toolName}>
                <td className="td-fill">{el.toolName}</td>
                <td>
                  <p className="bold-text">{el.type}</p>
                  <p className="normal-text">
                    {el.description}
                    <details>
                      <summary className="my-1 info-title"> For example.</summary>
                      {el.example}
                    </details>
                  </p>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <h5>Possible values for the fields</h5>
      <table>
        <thead>
          <tr>
            <th></th>
            <th className="py-2">
              <span className="info-title info-sz">
                *Some values are available from a certain pricing plan. <Link to="/pricing">(check my plan)</Link>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {toolObject &&
            toolObject.possibleValues.map((el: any) => (
              <tr key={el.title}>
                <td className="td-fill">{el.title}</td>
                <td>
                  <p className="py-2 normal-text">{el.description}</p>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <h5>Example</h5>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">{toolObject && toolObject.exampleBodyFields.address_list}</p>
        <p className="mgl-25">{toolObject && toolObject.exampleBodyFields.tool}</p>
        <p className="mgl-25">{toolObject && toolObject.exampleBodyFields.notification_integrations}</p>
        <p className="mgl-25">{toolObject && toolObject.exampleBodyFields.change_notification_level}</p>
        <p className="mgl-25">{toolObject && toolObject.exampleBodyFields.monitoring_interval}</p>
        <p className="mgl-25">{toolObject && toolObject.exampleBodyFields.pause_after_first_change_event}</p>
        <p className="mgl-25">{toolObject && toolObject.exampleBodyFields.tags}</p>
        <p className="mgl-25">{toolObject && toolObject.exampleBodyFields.webhook}</p>
        <p className="mgl-25">{toolObject && toolObject.exampleBodyFields.tool_settings}</p>
        <p className="mgl-50">{toolObject && toolObject.exampleBodyFields.proxy}</p>
        <p className="mgl-50">{toolObject && toolObject.exampleBodyFields.api_host_code}</p>
        <p className="mgl-50">{toolObject && toolObject.exampleBodyFields.user_agent}</p>
        {toolShortName !== 'availabilityMonitoringTool' && (
          <p className="mgl-50">{toolObject && toolObject.exampleBodyFields.mode}</p>
        )}
        {toolShortName === 'techStackTool' && (
          <p className="mgl-50">{toolObject && toolObject.exampleBodyFields.specific_tech_stacks}</p>
        )}
        {toolShortName === 'keywordTool' && (
          <>
            <p className="mgl-50">{toolObject && toolObject.exampleBodyFields.operators}</p>
            <p className="mgl-50">{toolObject && toolObject.exampleBodyFields.keywords}</p>
          </>
        )}
        {toolShortName === 'visualMonitoringTool' && (
          <>
            <p className="mgl-50">{toolObject && toolObject.exampleBodyFields.adblock}</p>
            <p className="mgl-50">{toolObject && toolObject.exampleBodyFields.device}</p>
            <p className="mgl-50">{toolObject && toolObject.exampleBodyFields.full_stack}</p>
          </>
        )}
        {toolShortName === 'sourceCodeMonitoringTool' && (
          <>
            <p className="mgl-50">{toolObject && toolObject.exampleBodyFields.specific_codes}</p>
            <p className="mgl-50">{toolObject && toolObject.exampleBodyFields.file_type}</p>
          </>
        )}
        {toolShortName === 'domainWhoisTool' && (
          <>
            <p className="mgl-50">{toolObject && toolObject.exampleBodyFields.specific_fields}</p>
            <p className="mgl-50">{toolObject && toolObject.exampleBodyFields.alertDay}</p>
          </>
        )}

        {toolShortName === 'contentMonitoringTool' && (
          <p className="mgl-50">{toolObject && toolObject.exampleBodyFields.contents}</p>
        )}
        {toolShortName === 'backlinkTool' && (
          <p className="mgl-50">{toolObject && toolObject.exampleBodyFields.keyword}</p>
        )}

        <p className="mgl-25">{'}'}</p>
        <p>{'}'}</p>
      </pre>

      <h5>Response Body</h5>

      <p>
        if request is <strong className="info-title">successful</strong> you will get
      </p>
      <div className="json-title-block">
        <span>JSON representation</span>
      </div>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": false,</p>
        <p className="mgl-25">"message": "New monitoring(s) created.",</p>
        <p className="mgl-25">"monitoring_ids": [ 1240 ]</p>
        <p>{'}'}</p>
      </pre>

      <p>
        if request is <strong className="danger-color">not</strong> successful you will get
      </p>
      <div className="json-title-block">
        <span>JSON representation</span>
      </div>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": true,</p>
        <p className="mgl-25">"message": String</p>
        <p>{'}'}</p>
      </pre>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">error</td>
            <td>
              <p className="bold-text">boolean</p>
              <p className="normal-text">if the error returns true, it means that an error has occurred</p>
            </td>
          </tr>
          <tr>
            <td className="td-fill">message</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">the message contains information about the error</p>
            </td>
          </tr>
        </tbody>
      </table>

      <h5>Example</h5>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": true,</p>
        <p className="mgl-25">"message": "invalid API key"</p>
        <p>{'}'}</p>
      </pre>
    </>
  );
};

export default PostRequestForTools;
