import React, {ReactNode} from 'react';
import {TechStachNames} from '../../constans';
import {Link} from 'gatsby';

// ------------------ start of interfaces ---------------------

interface commonRequestBodyType {
  address_list: string;
  notification_integrations: string;
  change_notification_level: string;
  monitoring_interval: string;
  pause_after_first_change_event: string;
  webhook: string;
  tool_settings: string;
  tags: string;
}

interface possibleValuesType {
  title: string;
  description: string | ReactNode;
}

interface commonRequestBodyFieldsTypes {
  toolName: string;
  type: string | ReactNode;
  description: string | ReactNode;
  example: string | ReactNode;
}
export interface toolArrayType {
  toolFullName: string;
  toolShortName: string;
  possibleValues: possibleValuesType[];
  requestBodyFields: commonRequestBodyFieldsTypes[];
  exampleBodyFields: {[arg: string]: string};
}
// ------------------ end of interfaces ---------------------

const commonRequestBody: commonRequestBodyType = {
  address_list: `"address_list": ["example1.com", "example2.com"],`,
  notification_integrations: `"notification_integrations": [260, 261],`,
  change_notification_level: `"change_notification_level": "ANY",`,
  monitoring_interval: `"monitoring_interval": "2_WEEK",`,
  pause_after_first_change_event: `"pause_after_first_change_event": false,`,
  webhook: `"webhook": "your-webhook",`,
  tags: `"tags": ["tag1","tag2"],`,
  tool_settings: `"tool_settings": { `,
};

const possibleValues: possibleValuesType[] = [
  {
    title: 'api_host_code',
    description: `"EUROPE", "USA", "ASIA",`,
  },
  {
    title: 'monitoring_interval',
    description: `"5_MINUTE",  "10_MINUTE", "15_MINUTE", "30_MINUTE",
    "1_HOUR", "2_HOUR","3_HOUR", "4_HOUR",  "5_HOUR", "6_HOUR",
    "12_HOUR", "1_DAY", "2_DAY", "3_DAY", "1_WEEK", "2_WEEK",
    "1_MONTH", "2_MONTH", "3_MONTH"
    `,
  },
  {
    title: 'proxy type',
    description: (
      <>
        <span className="info-title bold-text">
          *Proxy type changes are available from
          <Link to="/pricing"> Pro </Link>
          pricing plan.
        </span>
        <br />
        "premium"
      </>
    ),
  },
  {
    title: 'proxy country_code',
    description: (
      <>
        <span className="info-title bold-text">
          *Proxy country code changes are available from
          <Link to="/pricing"> Pro </Link>
          pricing plan.
        </span>
        <br />
        "AF", "AL", "DZ", "AD", "AO", "AR", "AU", "AT", "AZ", "BH", "BD", "BB", "BY", "BJ", "BO", "BW", "BR", "BG",
        "BF", "BI", "KH", "CM", "CA", "CV", "CL", "CN", "CO", "CG", "CR", "HR", "CU", "CY", "CZ", "CI", "DK", "DO",
        "EC", "EG", "SV", "EE", "ET", "FJ", "FI", "FR", "GM", "GE", "DE", "GH", "GR", "GL", "GT", "GN", "HT", "HN",
        "HK", "HU", "IN", "ID", "IR", "IQ", "IL", "IT", "JM", "JP", "JO", "KE", "KZ", "KR", "KW", "KG", "LV", "LB",
        "LT", "MO", "MK", "MG", "MW", "MY", "MV", "ML", "MT", "MR", "MU", "MX", "MD", "MN", "MA", "MZ", "MM", "NA",
        "NP", "NL", "NI", "NE", "NG", "PK", "PA", "PY", "PE", "PH", "PL", "PT", "PR", "QA", "RO", "RU", "RW", "RE",
        "LC", "SM", "SA", "SN", "RS", "SL", "SG", "SK", "SI", "SO", "ZA", "ES", "LK", "SD", "SE", "TZ", "TH", "TG",
        "TT", "TN", "TR", "UG", "UA", "AE", "GB", "US", "UY", "UZ", "VE", "VN", "ZW"
      </>
    ),
  },
];

const commonRequestBodyFields: commonRequestBodyFieldsTypes[] = [
  {
    toolName: 'address_list',
    type: (
      <>
        string[] (required)<span className="info-title">*</span>
      </>
    ),
    description: 'You should provide an address list of monitoring urls.',
    example: 'address_list : ["example1.com", "example2.com"]',
  },
  {
    toolName: 'notification_integrations',
    type: (
      <>
        number[] (optional,
        <span className="info-title">
          {' '}
          default <span className="ml-1">[]</span>
        </span>
        )
      </>
    ),
    description: (
      <>
        You can choose which integration(s) from discord, slack, telegram, email you want to enable for this tool.
        <a href="https://dash.hexowatch.com/hexowatch/settings" className="info-title ml-2" no-index>
          <strong>Add an integration id to get notification </strong>
        </a>
      </>
    ),
    example: 'notification_integrations : [your-integration-id, your-integration-id]',
  },
  {
    toolName: 'change_notification_level',
    type: (
      <>
        string (optional,
        <span className="info-title"> default "ANY"</span>)
      </>
    ),
    description: `You should provide change level from one of the possible values.`,
    example: 'change_notification_level : "ANY"',
  },
  {
    toolName: 'monitoring_interval',
    type: (
      <>
        string (optional,
        <span className="info-title"> default "1_DAY"</span>)
      </>
    ),
    description: `You should provide monitoring interval from one of the possible values.`,
    example: 'monitoring_interval : "2_WEEK"',
  },
  {
    toolName: 'pause_after_first_change_event',
    type: (
      <>
        boolean (optional,
        <span className="info-title">
          {' '}
          default <span className="ml-1">false</span>
        </span>
        )
      </>
    ),
    description: 'You should decide whether to stop monitoring after the first change.',
    example: 'pause_after_first_change_event : false',
  },
  {
    toolName: 'tags',
    type: (
      <>
        string[] (optional,
        <span className="info-title">
          {' '}
          default <span className="ml-1">[]</span>
        </span>
        )
      </>
    ),
    description: 'You can send tags for each monitoring.',
    example: `"tags": ["tag1","tag2"]`,
  },
  {
    toolName: 'webhook',
    type: (
      <>
        string (optional,
        <span className="info-title">
          {' '}
          default <span className="ml-1">null</span>
        </span>
        )
      </>
    ),
    description: `We’ll make a request to this url, in case we get error from provided webhook 
      you will get an error, monitoring will not be created.`,
    example: `"webhook": "your-webhook"`,
  },
];

export const toolArray: toolArrayType[] = [
  {
    toolFullName: 'Technology monitoring',
    toolShortName: 'techStackTool',

    requestBodyFields: [
      {
        toolName: 'tool',
        type: (
          <>
            string (required)<span className="info-title">*</span>
          </>
        ),
        description: 'You should provide a specific tool name.',
        example: 'tool : "techStackTool"',
      },
      ...commonRequestBodyFields,

      {
        toolName: 'tool_settings',
        type: (
          <>
            object (see required fields and <span className="info-title">default values</span> in the example below)
          </>
        ),
        description: 'You should provide special settings for this tool',
        example: (
          <pre className="pre">
            tool_settings: {'{'} {'\n'}
            "proxy": {'{'} "type": "premium", "country_code": "AR" {'},'}
            <strong className="info-title">*REQUIRED if type === "premium"</strong>
            {'\n'}
            "api_host_code": "USA", <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "user_agent": false, <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "mode": "ANY_CHANGE",
            <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "specific_tech_stacks": ["AWStats"],
            <strong className="info-title">*REQUIRED if mode === SPECIFIC_TECH_STACK_SEARCH</strong>
            {'\n'}
            {'}'}
          </pre>
        ),
      },
    ],

    possibleValues: [
      ...possibleValues,
      {
        title: 'change_notification_level',
        description: `"ANY", "ADDED", "UPDATED","DELETED" `,
      },
      {
        title: 'mode',
        description: `"SPECIFIC_TECH_STACK_SEARCH", "ANY_CHANGE"`,
      },
      {
        title: 'specific_tech_stacks',
        description: (
          <>
            <span className="my-2 mr-2">Only one value in array as string, Choose the value from the list:</span>
            <input className="tech-stack-list" placeholder="Specific tech stack" list="teck-stack" />
            <datalist id="teck-stack">
              {TechStachNames.map((el: string) => (
                <option value={el} />
              ))}
            </datalist>
          </>
        ),
      },
    ],

    exampleBodyFields: {
      tool: `"tool": "techStackTool",`,
      ...commonRequestBody,
      proxy: `"proxy": { "type": "", "country_code": "" },`,
      api_host_code: `"api_host_code": "USA",`,
      user_agent: `"user_agent": false,`,
      mode: `"mode": "SPECIFIC_TECH_STACK_SEARCH",`,
      specific_tech_stacks: `"specific_tech_stacks": ["AWStats"],`,
    },
  },

  {
    toolFullName: 'Keyword monitoring',
    toolShortName: 'keywordTool',

    requestBodyFields: [
      {
        toolName: 'tool',
        type: (
          <>
            string (required)<span className="info-title">*</span>
          </>
        ),
        description: 'You should provide a specific tool name.',
        example: 'tool : "keywordTool"',
      },
      ...commonRequestBodyFields,

      {
        toolName: 'tool_settings',
        type: (
          <>
            object (see required fields and <span className="info-title">default values</span> in the example below)
          </>
        ),
        description: 'You should provide special settings for this tool',
        example: (
          <pre className="pre">
            tool_settings: {'{'} {'\n'}
            "proxy": {'{'} "type": "premium", "country_code": "AR" {'},'}
            <strong className="info-title">*REQUIRED if type === "premium"</strong>
            {'\n'}
            "api_host_code": "USA", <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "user_agent": false, <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "mode": "SEARCH", <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "operators": ["OR"],
            <strong className="info-title">*REQUIRED if keywords.length {'>'} 1</strong>
            {'\n'}
            "keywords": ["example1", "example2"],
            <strong className="info-title">*REQUIRED</strong> {'\n'}
            {'}'}
          </pre>
        ),
      },
    ],

    possibleValues: [
      ...possibleValues,
      {
        title: 'change_notification_level',
        description: `"ANY"`,
      },
      {
        title: 'mode',
        description: `"SEARCH"`,
      },
      {
        title: 'operators',
        description: `"OR", "AND"`,
      },
    ],

    exampleBodyFields: {
      tool: `"tool": "keywordTool",`,
      ...commonRequestBody,
      proxy: `"proxy": { "type": "", "country_code": "" },`,
      api_host_code: `"api_host_code": "USA",`,
      user_agent: `"user_agent": false,`,
      mode: `"mode": "SEARCH",`,
      operators: `"operators": ["OR"],`,
      keywords: `"keywords": ["example1", "example2"]`,
    },
  },

  {
    toolFullName: 'Visual monitoring',
    toolShortName: 'visualMonitoringTool',

    requestBodyFields: [
      {
        toolName: 'tool',
        type: (
          <>
            string (required)<span className="info-title">*</span>
          </>
        ),
        description: 'You should provide a specific tool name.',
        example: 'tool : "visualMonitoringTool"',
      },
      ...commonRequestBodyFields,

      {
        toolName: 'tool_settings',
        type: 'object (optional)',
        description: 'You should provide special settings for this tool',
        example: (
          <pre className="pre">
            tool_settings: {'{'} {'\n'}
            "proxy": {'{'} "type": "premium", "country_code": "AR" {'},'}
            <strong className="info-title">*REQUIRED if type === "premium"</strong>
            {'\n'}
            "api_host_code": "USA", <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "user_agent": false, <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "mode": "FULL_SCREEN", <strong className="info-title">*OPTIONAL, DEFALUT</strong>
            {'\n'}
            "adblock": false, <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "device": "LAPTOP_LARGE", <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "full_stack": false, <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            {'}'}
          </pre>
        ),
      },
    ],

    possibleValues: [
      ...possibleValues,
      {
        title: 'change_notification_level',
        description: `"ANY", "GE_1" , "GE_2", "GE_3" , "GE_4",
        "GE_5","GE_6", "GE_7", "GE_8", "GE_9" , "GE_10",
        "GE_11","GE_12", "GE_13", "GE_14", "GE_15", "GE_20", "GE_25","GE_50"`,
      },
      {
        title: 'mode',
        description: `"FULL_SCREEN"`,
      },
      {
        title: 'device',
        description: (
          <>
            <span className="info-title bold-text">
              *Device type changes are available from
              <Link to="/pricing"> Pro </Link>
              pricing plan.
            </span>
            <br />
            "MOBILE_SMALL", "MOBILE_MEDIUM", "MOBILE_LARGE", "TABLET", "LAPTOP_SMALL", "LAPTOP_MEDIUM", "LAPTOP_LARGE",
            "DESKTOP_4K"
          </>
        ),
      },
    ],

    exampleBodyFields: {
      tool: `"tool": "visualMonitoringTool",`,
      ...commonRequestBody,
      proxy: `"proxy": { "type": "", "country_code": "" },`,
      api_host_code: `"api_host_code": "USA",`,
      user_agent: `"user_agent": false,`,
      mode: `"mode": "FULL_SCREEN",`,
      adblock: `"adblock": true,`,
      device: `"device": "TABLET",`,
      full_stack: `"full_stack": true`,
    },
  },

  {
    toolFullName: 'Availability monitoring',
    toolShortName: 'availabilityMonitoringTool',

    requestBodyFields: [
      {
        toolName: 'tool',
        type: (
          <>
            string (required)<span className="info-title">*</span>
          </>
        ),
        description: 'You should provide a specific tool name.',
        example: 'tool : "availabilityMonitoringTool"',
      },
      ...commonRequestBodyFields,

      {
        toolName: 'tool_settings',
        type: 'object (optional)',
        description: 'You should provide special settings for this tool',
        example: (
          <pre className="pre">
            tool_settings: {'{'} {'\n'}
            "proxy": {'{'} "type": "premium", "country_code": "AR" {'},'}
            <strong className="info-title">*REQUIRED if type === "premium"</strong>
            {'\n'}
            "api_host_code": "USA", <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "user_agent": false, <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            {'}'}
          </pre>
        ),
      },
    ],

    possibleValues: [
      ...possibleValues,
      {
        title: 'change_notification_level',
        description: `"ANY"`,
      },
    ],

    exampleBodyFields: {
      tool: `"tool": "availabilityMonitoringTool",`,
      ...commonRequestBody,
      proxy: `"proxy": { "type": "", "country_code": "" },`,
      api_host_code: `"api_host_code": "USA",`,
      user_agent: `"user_agent": false,`,
    },
  },

  {
    toolFullName: 'Source code monitoring',
    toolShortName: 'sourceCodeMonitoringTool',

    requestBodyFields: [
      {
        toolName: 'tool',
        type: (
          <>
            string (required)<span className="info-title">*</span>
          </>
        ),
        description: 'You should provide a specific tool name.',
        example: 'tool : "sourceCodeMonitoringTool"',
      },

      ...commonRequestBodyFields,
      {
        toolName: 'tool_settings',
        type: (
          <>
            object (see required fields and <span className="info-title">default values</span> in the example below)
          </>
        ),
        description: 'You should provide special settings for this tool',
        example: (
          <pre className="pre">
            tool_settings: {'{'} {'\n'}
            "proxy": {'{'} "type": "premium", "country_code": "AR" {'},'}
            <strong className="info-title">*REQUIRED if type === "premium"</strong>
            {'\n'}
            "api_host_code": "USA", <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "user_agent": false, <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "mode": "FULL_CODE",
            <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "specific_codes": {`["<h1>Hello world!</h1>"]`},
            <strong className="info-title">*REQUIRED if mode === SPECIFIC_CODE </strong>
            {'\n'}
            "file_type": "HTML", <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            {'}'}
          </pre>
        ),
      },
    ],

    possibleValues: [
      ...possibleValues,
      {
        title: 'file_type',
        description: `"HTML", "CSS" , "JS"`,
      },
      {
        title: 'mode',
        description: `"SPECIFIC_CODE", "FULL_CODE"`,
      },
      {
        title: 'change_notification_level',
        description: `"ANY"`,
      },
    ],

    exampleBodyFields: {
      tool: `"tool": "sourceCodeMonitoringTool",`,
      ...commonRequestBody,
      proxy: `"proxy": { "type": "", "country_code": "" },`,
      api_host_code: `"api_host_code": "USA",`,
      user_agent: `"user_agent": false,`,
      mode: `"mode": "SPECIFIC_CODE",`,
      specific_codes: `"specific_codes": ["<h1>Hello world!</h1>"],`,
      file_type: `"file_type": "HTML",`,
    },
  },

  {
    toolFullName: 'Domain WHOIS monitoring',
    toolShortName: 'domainWhoisTool',

    requestBodyFields: [
      {
        toolName: 'tool',
        type: (
          <>
            string (required)<span className="info-title">*</span>
          </>
        ),
        description: 'You should provide a specific tool name.',
        example: 'tool : "domainWhoisTool"',
      },

      ...commonRequestBodyFields,
      {
        toolName: 'tool_settings',
        type: (
          <>
            object (see required fields and <span className="info-title">default values</span> in the example below)
          </>
        ),
        description: 'You should provide special settings for this tool',
        example: (
          <pre className="pre">
            tool_settings: {'{'} {'\n'}
            "api_host_code": "USA", <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "user_agent": false, <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "mode": "ANY_CHANGE",
            <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "specific_fields": ["lastModified"],
            <strong className="info-title">*REQUIRED if mode === SPECIFIC_FIELDS</strong>
            {'\n'}
            "alertDay": 7, <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            {'}'}
          </pre>
        ),
      },
    ],

    possibleValues: [
      ...possibleValues.slice(0, 3),
      {
        title: 'change_notification_level',
        description: `"ANY"`,
      },
      {
        title: 'mode',
        description: `"ANY_CHANGE", "SPECIFIC_FIELDS"`,
      },
      {
        title: 'alertDay',
        description: `null, -90, -80, -70, -60, -45, -30, -15, -7, -1, 0, 1, 7, 15, 30, 45, 60, 70, 80, 90`,
      },
      {
        title: 'specific_fields',
        description: `"registeredAt", "lastModified", "fullText", "expiresAt",
        "exists", "domain", "url", "dnsData", "status",`,
      },
    ],

    exampleBodyFields: {
      tool: `"tool": "domainWhoisTool",`,
      ...commonRequestBody,
      api_host_code: `"api_host_code": "USA",`,
      user_agent: `"user_agent": false,`,
      mode: `"mode": "SPECIFIC_FIELDS",`,
      specific_fields: `"specific_fields": ["lastModified"],`,
      alertDay: `"alertDay": 7,`,
    },
  },

  {
    toolFullName: 'Content monitoring',
    toolShortName: 'contentMonitoringTool',

    requestBodyFields: [
      {
        toolName: 'tool',
        type: (
          <>
            string (required)<span className="info-title">*</span>
          </>
        ),
        description: 'You should provide a specific tool name.',
        example: 'tool : "contentMonitoringTool"',
      },
      ...commonRequestBodyFields,
      {
        toolName: 'tool_settings',
        type: (
          <>
            object (see required fields and <span className="info-title">default values</span> in the example below)
          </>
        ),
        description: 'You should provide special settings for this tool',
        example: (
          <pre className="pre">
            tool_settings: {'{'} {'\n'}
            "proxy": {'{'} "type": "premium", "country_code": "AR" {'},'}
            <strong className="info-title">*REQUIRED if type === "premium"</strong>
            {'\n'}
            "api_host_code": "USA", <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "user_agent": false, <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "mode": "FULL_CONTENT",
            <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "contents": ["content here"],
            <strong className="info-title">*REQUIRED if mode === SPECIFIC_CONTENT</strong>
            {'\n'}
            {'}'}
          </pre>
        ),
      },
    ],

    possibleValues: [
      ...possibleValues,
      {
        title: 'change_notification_level',
        description: `"ANY", "GE_1" , "GE_2", "GE_3" , "GE_4",
        "GE_5","GE_6", "GE_7", "GE_8", "GE_9" , "GE_10",
        "GE_11","GE_12", "GE_13", "GE_14", "GE_15", "GE_20", "GE_25","GE_50"`,
      },
      {
        title: 'mode',
        description: `"FULL_CONTENT", "SPECIFIC_CONTENT"`,
      },
    ],

    exampleBodyFields: {
      tool: `"tool": "contentMonitoringTool",`,
      ...commonRequestBody,
      proxy: `"proxy": { "type": "", "country_code": "" },`,
      api_host_code: `"api_host_code": "USA",`,
      user_agent: `"user_agent": false,`,
      mode: `"mode": "SPECIFIC_CONTENT",`,
      contents: `"contents": ["content here"],`,
    },
  },

  {
    toolFullName: 'Backlink monitoring',
    toolShortName: 'backlinkTool',

    requestBodyFields: [
      {
        toolName: 'tool',
        type: (
          <>
            string (required)<span className="info-title">*</span>
          </>
        ),
        description: 'You should provide a specific tool name.',
        example: 'tool : "backlinkTool"',
      },

      ...commonRequestBodyFields,
      {
        toolName: 'tool_settings',
        type: (
          <>
            object (see required fields and <span className="info-title">default values</span> in the example below)
          </>
        ),
        description: 'You should provide special settings for this tool',
        example: (
          <pre className="pre">
            tool_settings: {'{'} {'\n'}
            "proxy": {'{'} "type": "premium", "country_code": "AR" {'},'}
            <strong className="info-title">*REQUIRED if type === "premium"</strong>
            {'\n'}
            "api_host_code": "USA", <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "user_agent": false, <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "mode": "FULL_DATA",
            <strong className="info-title">*OPTIONAL, DEFAULT</strong>
            {'\n'}
            "keyword": "backlink",
            <strong className="info-title">*REQUIRED</strong>
            {'\n'}
            {'}'}
          </pre>
        ),
      },
    ],

    possibleValues: [
      ...possibleValues,
      {
        title: 'change_notification_level',
        description: `"ANY"`,
      },
      {
        title: 'mode',
        description: `"FULL_DATA"`,
      },
      {
        title: 'keyword',
        description: `"backlink"`,
      },
    ],

    exampleBodyFields: {
      tool: `"tool": "backlinkTool",`,
      ...commonRequestBody,
      proxy: `"proxy": { "type": "", "country_code": "" },`,
      api_host_code: `"api_host_code": "USA",`,
      user_agent: `"user_agent": false,`,
      mode: `"mode": "FULL_DATA",`,
      keyword: `"keyword": "backlink",`,
    },
  },
];
