import React from "react";

export const TechStachNames: string[] = [
  "1C-Bitrix",
  "91App",
  "3dCart",
  "A-Frame",
  "AD EBiS",
  "AOLserver",
  "AT Internet Analyzer",
  "AT Internet XiTi",
  "AWStats",
  "AMP",
  "AMP Plugin",
  "Apollo",
  "Arc Publishing",
  "Azure",
  "Azure CDN",
  "Acquia Cloud",
  "Act-On",
  "AdInfinity",
  "AdOcean",
  "AdRiver",
  "AdRoll",
  "Adcash",
  "AddShoppers",
  "AddThis",
  "AddToAny",
  "Adminer",
  "Adnegah",
  "Adobe ColdFusion",
  "Adobe DTM",
  "Adobe Experience Manager",
  "Adobe GoLive",
  "Adobe Muse",
  "Adobe RoboHelp",
  "ADPLAN",
  "Advanced Web Stats",
  "Advert Stream",
  "Adverticum",
  "Adyen",
  "Adzerk",
  "Aegea",
  "Afosto",
  "AfterBuy",
  "Ahoy",
  "Aircall",
  "Airee",
  "Airform",
  "Akamai",
  "Akaunting",
  "Akka HTTP",
  "Algolia Realtime Search",
  "All in One SEO Pack",
  "Allegro RomPager",
  "AlloyUI",
  "Amaya",
  "Amazon Cloudfront",
  "Amazon EC2",
  "Amazon Web Services",
  "Amazon ECS",
  "Amazon ELB",
  "Amazon S3",
  "Amber",
  "Ametys",
  "Amiro.CMS",
  "Amplitude",
  "Analysys Ark",
  "Anetwork",
  "Angular",
  "Angular Material",
  "AngularDart",
  "AngularJS",
  "Ant Design",
  "Apache",
  "Apache HBase",
  "Apache Hadoop",
  "Apache JSPWiki",
  "Apache Tomcat",
  "Apache Traffic Server",
  "Apache Wicket",
  "ApexPages",
  "Apigee",
  "Apostrophe CMS",
  "AppNexus",
  "Appcues",
  "Arastta",
  "ArcGIS API for JavaScript",
  "Artifactory",
  "Artifactory Web Server",
  "ArvanCloud",
  "AsciiDoc",
  "Asciinema",
  "Atlassian Bitbucket",
  "Atlassian Confluence",
  "Atlassian FishEye",
  "Atlassian Jira",
  "Atlassian Jira Issue Collector",
  "Aurelia",
  "Avangate",
  "Avasize",
  "Awesomplete",
  "BEM",
  "BIGACE",
  "Babel",
  "Bablic",
  "Backbone.js",
  "Backdrop",
  "Backpack",
  "Backtory",
  "Banshee",
  "BaseHTTP",
  "BigBangShop",
  "BigDump",
  "Bigcommerce",
  "Bigware",
  "BittAds",
  "Bizweb",
  "Blade",
  "Blazor",
  "Blesta",
  "Blip.tv",
  "Blogger",
  "Bluefish",
  "Boa",
  "Boba.js",
  "Bold Chat",
  "BoldGrid",
  "Bolt",
  "BOOM",
  "Bonfire",
  "Bootstrap",
  "Bootstrap Table",
  "ClickFunnels",
  "Bounce Exchange",
  "Braintree",
  "Brightspot",
  "BrowserCMS",
  "Bubble",
  "BugSnag",
  "Bugzilla",
  "Bulma",
  "Burning Board",
  "Business Catalyst",
  "BuySellAds",
  "CDN77",
  "CFML",
  "CKEditor",
  "CMS Made Simple",
  "CMSimple",
  "CPG Dragonfly",
  "CS Cart",
  "CacheFly",
  "Caddy",
  "CakePHP",
  "Captch Me",
  "Carbon Ads",
  "Cargo",
  "Catberry.js",
  "CentOS",
  "Chameleon",
  "Chamilo",
  "Chart.js",
  "Chartbeat",
  "Cherokee",
  "CherryPy",
  "Chevereto",
  "Chitika",
  "Chorus",
  "Ckan",
  "Clarity",
  "ClickHeat",
  "ClickTale",
  "Clicky",
  "Clientexec",
  "Clipboard.js",
  "CloudCart",
  "CloudFlare",
  "Cloudcoins",
  "Cloudera",
  "Coaster CMS",
  "CodeIgniter",
  "CodeMirror",
  "CoinHive",
  "CoinHive Captcha",
  "Coinhave",
  "Coinimp",
  "Coinlab",
  "ColorMeShop",
  "Comandia",
  "Combeenation",
  "Commerce Server",
  "CompaqHTTPServer",
  "Concrete5",
  "Connect",
  "Contao",
  "Contenido",
  "Contensis",
  "ContentBox",
  "Contentful",
  "ConversionLab",
  "Coppermine",
  "Cosmoshop",
  "Cotonti",
  "CouchDB",
  "Countly",
  "Cowboy",
  "CppCMS",
  "Craft CMS",
  "Craft Commerce",
  "Crazy Egg",
  "Criteo",
  "Cross Pixel",
  "CrossBox",
  "Crypto-Loot",
  "CubeCart",
  "Cufon",
  "D3",
  "DHTMLX",
  "DERAK.CLOUD",
  "DM Polopoly",
  "DNN",
  "DTG",
  "Dancer",
  "Danneo CMS",
  "Dart",
  "Darwin",
  "Datadome",
  "DataLife Engine",
  "DataTables",
  "Day.js",
  "Debian",
  "DedeCMS",
  "DirectAdmin",
  "Discourse",
  "Discuz! X",
  "Disqus",
  "Django",
  "Django CMS",
  "Docusaurus",
  "Docker",
  "Dojo",
  "Dokeos",
  "DokuWiki",
  "Dotclear",
  "DoubleClick Ad Exchange (AdX)",
  "DoubleClick Campaign Manager (DCM)",
  "DoubleClick Floodlight",
  "DoubleClick for Publishers (DFP)",
  "DovetailWRP",
  "Doxygen",
  "DreamWeaver",
  "Drupal",
  "Drupal Commerce",
  "Dynamicweb",
  "Dynatrace",
  "EasyEngine",
  "EC-CUBE",
  "Elementor",
  "ELOG",
  "ELOG HTTP",
  "EPages",
  "EPiServer",
  "EPrints",
  "EdgeCast",
  "Elcodi",
  "Eleanor CMS",
  "Element UI",
  "Eloqua",
  "EmbedThis Appweb",
  "Ember.js",
  "Ensighten",
  "Envoy",
  "Enyo",
  "Epoch",
  "Epom",
  "Erlang",
  "Essential JS 2",
  "Etherpad",
  "Exhibit",
  "Express",
  "ExpressionEngine",
  "ExtJS",
  "F5 BigIP",
  "FAST ESP",
  "FAST Search for SharePoint",
  "FWP",
  "Facebook",
  "Fact Finder",
  "FancyBox",
  "Fastcommerce",
  "Fastly",
  "Fat-Free Framework",
  "Fbits",
  "Fedora",
  "Fingerprintjs",
  "Firebase",
  "Fireblade",
  "Flarum",
  "Flask",
  "Flat UI",
  "FlexCMP",
  "FlexSlider",
  "Flickity",
  "FluxBB",
  "Flyspray",
  "Flywheel",
  "Font Awesome",
  "Fork CMS",
  "Fortune3",
  "Foswiki",
  "FreeBSD",
  "FreeTextBox",
  "Freespee",
  "Freshchat",
  "Freshmarketer",
  "Froala Editor",
  "FrontPage",
  "Fusion Ads",
  "Future Shop",
  "G-WAN",
  "GX WebManager",
  "Gallery",
  "Gambio",
  "Gatsby",
  "Gauges",
  "Gemius",
  "Gentoo",
  "Gerrit",
  "Get Satisfaction",
  "GetSimple CMS",
  "Ghost",
  "GitBook",
  "GitHub Pages",
  "GitLab",
  "GitLab CI",
  "Gitea",
  "Gitiles",
  "GlassFish",
  "Glyphicons",
  "Go",
  "GoAhead",
  "GoDaddy Website Builder",
  "GoJS",
  "GoSquared",
  "GoStats",
  "Gogs",
  "Google AdSense",
  "Google Analytics",
  "Google Analytics Enhanced eCommerce",
  "Google App Engine",
  "Google Charts",
  "Google Cloud",
  "Google Code Prettify",
  "Google Font API",
  "Google Maps",
  "Google PageSpeed",
  "Google Plus",
  "Google Sites",
  "Google Tag Manager",
  "Google Wallet",
  "Google Web Server",
  "Google Web Toolkit",
  "Graffiti CMS",
  "Grav",
  "Gravatar",
  "Gravity Forms",
  "Green Valley CMS",
  "Gridsome",
  "GrowingIO",
  "HERE",
  "HHVM",
  "HP ChaiServer",
  "HP Compact Server",
  "HP ProCurve",
  "HP System Management",
  "HP iLO",
  "HTTP/2",
  "Haddock",
  "Hammer.js",
  "Handlebars",
  "Haravan",
  "Haskell",
  "HeadJS",
  "Heap",
  "Hello Bar",
  "Hexo",
  "Hiawatha",
  "Highcharts",
  "Bokeh",
  "Highlight.js",
  "Highstock",
  "Hinza Advanced CMS",
  "Bloomreach",
  "Hogan.js",
  "Homeland",
  "Hotaru CMS",
  "Hotjar",
  "HubSpot",
  "Hugo",
  "Hybris",
  "IBM Coremetrics",
  "IBM DataPower",
  "IBM HTTP Server",
  "IBM Tivoli Storage Manager",
  "IBM WebSphere Commerce",
  "IBM WebSphere Portal",
  "IIS",
  "INFOnline",
  "INTI",
  "IPB",
  "Ideasoft",
  "IdoSell Shop",
  "Immutable.js",
  "ImpressCMS",
  "ImpressPages",
  "Incapsula",
  "PageCDN",
  "Includable",
  "Indexhibit",
  "Indico",
  "Indy",
  "InfernoJS",
  "Infusionsoft",
  "Inspectlet",
  "Instabot",
  "InstantCMS",
  "Intel Active Management Technology",
  "IntenseDebate",
  "Intercom",
  "Intershop",
  "Invenio",
  "Inwemo",
  "Ionic",
  "Ionicons",
  "JAlbum",
  "JBoss Application Server",
  "JBoss Web",
  "JET Enterprise",
  "JS Charts",
  "JSEcoin",
  "JTL Shop",
  "Jahia DX",
  "Jalios",
  "Java",
  "Java Servlet",
  "JavaScript Infovis Toolkit",
  "JavaServer Faces",
  "JavaServer Pages",
  "Jekyll",
  "Jenkins",
  "Jetshop",
  "Jetty",
  "Jimdo",
  "Jirafe",
  "Jive",
  "JobberBase",
  "Joomla",
  "K2",
  "KISSmetrics",
  "Kajabi",
  "Kampyle",
  "Kamva",
  "Kemal",
  "Kendo UI",
  "Kentico CMS",
  "Kestrel",
  "KeyCDN",
  "Kibana",
  "KineticJS",
  "Klarna Checkout",
  "Knockout.js",
  "Koa",
  "Koala Framework",
  "KobiMaster",
  "Koha",
  "Kohana",
  "Koken",
  "Kolibri CMS",
  "Komodo CMS",
  "Kontaktify",
  "Koobi",
  "Kooboo CMS",
  "Kotisivukone",
  "Kubernetes Dashboard",
  "LEPTON",
  "LabVIEW",
  "Laravel",
  "Laterpay",
  "Lazy.js",
  "Leaflet",
  "Less",
  "Liferay",
  "Lift",
  "LightMon Engine",
  "Lightbox",
  "Lightspeed eCom",
  "Lighty",
  "LimeSurvey",
  "LinkSmart",
  "Linkedin",
  "Liquid Web",
  "List.js",
  "LiteSpeed",
  "Lithium",
  "LiveAgent",
  "LiveChat",
  "LiveHelp",
  "LiveJournal",
  "LivePerson",
  "LiveStreet CMS",
  "Livefyre",
  "Liveinternet",
  "LocalFocus",
  "Locomotive",
  "Lodash",
  "Logitech Media Server",
  "Lotus Domino",
  "LOU",
  "Lua",
  "Lucene",
  "LuigiвЂ™s Box",
  "MODX",
  "MYPAGE Platform",
  "Botble CMS",
  "MadAdsMedia",
  "Magento",
  "MailChimp",
  "MakeShopKorea",
  "Mambo",
  "MantisBT",
  "ManyContacts",
  "MariaDB",
  "Marionette.js",
  "Marked",
  "Marketo",
  "Material Design Lite",
  "Materialize CSS",
  "MathJax",
  "Matomo",
  "Mattermost",
  "Mautic",
  "MaxCDN",
  "MaxSite CMS",
  "Mean.io",
  "MediaElement.js",
  "MediaTomb",
  "MediaWiki",
  "Medium",
  "Meebo",
  "Melis CMS V2",
  "MemberStack",
  "Mermaid",
  "Meteor",
  "Methode",
  "Microsoft ASP.NET",
  "Microsoft Excel",
  "Microsoft HTTPAPI",
  "Microsoft PowerPoint",
  "Microsoft Publisher",
  "Microsoft SharePoint",
  "Microsoft Word",
  "Mietshop",
  "Milligram",
  "Minero.cc",
  "MiniBB",
  "MiniServ",
  "Mint",
  "Mithril",
  "Mixpanel",
  "MkDocs",
  "Mobify",
  "Mobirise",
  "MobX",
  "MochiKit",
  "MochiWeb",
  "Modernizr",
  "Modified",
  "Moguta.CMS",
  "MoinMoin",
  "Mojolicious",
  "Mollom",
  "Moment Timezone",
  "Moment.js",
  "Mondo Media",
  "Monerominer",
  "MongoDB",
  "Mongrel",
  "Monkey HTTP Server",
  "Mono",
  "Mono.net",
  "MooTools",
  "Moodle",
  "Moon",
  "MotoCMS",
  "Mouse Flow",
  "Movable Type",
  "Mozard Suite",
  "Mura CMS",
  "Mustache",
  "MyBB",
  "MyBlogLog",
  "MySQL",
  "Mynetcap",
  "NEO - Omnichannel Commerce Platform",
  "NVD3",
  "Navegg",
  "Neos CMS",
  "Neos Flow",
  "Nepso",
  "Netlify",
  "Neto",
  "Netsuite",
  "Nette Framework",
  "New Relic",
  "Next.js",
  "NextGEN Gallery",
  "Nginx",
  "Node.js",
  "NodeBB",
  "Now",
  "OWL Carousel",
  "OXID eShop",
  "October CMS",
  "Octopress",
  "Odoo",
  "Olark",
  "OneAPM",
  "OneStat",
  "Open AdStream",
  "Open Classifieds",
  "Open Journal Systems",
  "Open Web Analytics",
  "Open eShop",
  "OpenBSD httpd",
  "OpenCart",
  "OpenCms",
  "OpenGSE",
  "OpenGrok",
  "OpenLayers",
  "OpenNemas",
  "OpenResty",
  "OpenSSL",
  "OpenText Web Solutions",
  "OpenUI5",
  "OpenX",
  "Optimizely",
  "Oracle Application Server",
  "Oracle Commerce",
  "Oracle Commerce Cloud",
  "Oracle Dynamic Monitoring Service",
  "Oracle HTTP Server",
  "Oracle Recommendations On Demand",
  "Oracle Web Cache",
  "Orchard CMS",
  "OroCommerce",
  "Outbrain",
  "Outlook Web App",
  "PANSITE",
  "PDF.js",
  "PHP",
  "PHP-Fusion",
  "PHP-Nuke",
  "PHPDebugBar",
  "Cecil",
  "Pagekit",
  "Pagevamp",
  "Pantheon",
  "Paper.js",
  "Pardot",
  "Pars Elecom Portal",
  "Parse.ly",
  "Paths.js",
  "PayPal",
  "Pelican",
  "PencilBlue",
  "Pendo",
  "Percona",
  "Percussion",
  "Perl",
  "Phabricator",
  "Phaser",
  "Phenomic",
  "Phusion Passenger",
  "Pimcore",
  "Pingoteam",
  "Pinterest",
  "Planet",
  "PlatformOS",
  "Platform.sh",
  "Play",
  "Plentymarkets",
  "Plesk",
  "Pligg",
  "Plone",
  "Plotly",
  "Po.st",
  "Polyfill",
  "Polymer",
  "Posterous",
  "PostgreSQL",
  "Powergap",
  "Prebid",
  "Prefix-Free",
  "PrestaShop",
  "Prism",
  "Project Wonderful",
  "ProjectPoi",
  "Projesoft",
  "Prototype",
  "Protovis",
  "Proximis Omnichannel",
  "Proximis Web to Store",
  "PubMatic",
  "Public CMS",
  "Pure CSS",
  "Pygments",
  "PyroCMS",
  "Python",
  "Quantcast",
  "Question2Answer",
  "Quick.CMS",
  "Quick.Cart",
  "Quill",
  "RBS Change",
  "RCMS",
  "RD Station",
  "RDoc",
  "RackCache",
  "RainLoop",
  "Rakuten DBCore",
  "Rakuten Digital Commerce",
  "Ramda",
  "Raphael",
  "Raspbian",
  "Raychat",
  "Rayo",
  "Rdf",
  "Redaxscript",
  "ReDoc",
  "React",
  "Red Hat",
  "Reddit",
  "Redmine",
  "Reinvigorate",
  "RequireJS",
  "Resin",
  "Reveal.js",
  "Revel",
  "Revslider",
  "Rickshaw",
  "RightJS",
  "Riot",
  "RiteCMS",
  "Roadiz CMS",
  "Robin",
  "RockRMS",
  "RoundCube",
  "Rubicon Project",
  "Ruby",
  "Ruby on Rails",
  "Ruxit",
  "RxJS",
  "S.Builder",
  "SAP",
  "Sapper",
  "Scenari",
  "SDL Tridion",
  "Section.io",
  "Sensors Data",
  "Sentry",
  "SIMsite",
  "SMF",
  "SOBI 2",
  "SPDY",
  "SPIP",
  "SQL Buddy",
  "SQLite",
  "SUSE",
  "Swiper Slider",
  "SWFObject",
  "Saia PCD",
  "Sails.js",
  "Salesforce",
  "Salesforce Commerce Cloud",
  "Sarka-SPIP",
  "Sazito",
  "Phoenix",
  "Scala",
  "Scholica",
  "Scientific Linux",
  "SeamlessCMS",
  "Segment",
  "Select2",
  "Semantic-ui",
  "Sencha Touch",
  "Seravo",
  "Serendipity",
  "Shapecss",
  "ShareThis",
  "ShellInABox",
  "Shiny",
  "ShinyStat",
  "Shopatron",
  "Shopcada",
  "Shoper",
  "Shopery",
  "Shopfa",
  "Shopify",
  "Shopline",
  "Shoptet",
  "Shopware",
  "Signal",
  "Silva",
  "SilverStripe",
  "Simple Analytics",
  "SimpleHTTP",
  "SimplГ©bo",
  "Site Meter",
  "SiteCatalyst",
  "SiteEdit",
  "Sitecore",
  "SiteGround",
  "Sitefinity",
  "Sivuviidakko",
  "Sizmek",
  "Slick",
  "Slimbox",
  "Slimbox 2",
  "Smart Ad Server",
  "SmartSite",
  "Smartstore",
  "Snap",
  "Snap.svg",
  "Snoobi",
  "SobiPro",
  "Socket.io",
  "SoftTr",
  "Solodev",
  "Solr",
  "Solusquare OmniCommerce Cloud",
  "Solve Media",
  "SonarQubes",
  "SoundManager",
  "Sphinx",
  "SpiderControl iniNet",
  "SpinCMS",
  "Splunk",
  "Splunkd",
  "Spree",
  "Sqreen",
  "Squarespace",
  "SquirrelMail",
  "Squiz Matrix",
  "Stackla",
  "Starlet",
  "Statcounter",
  "Store Systems",
  "Storeden",
  "Storyblok",
  "Strapdown.js",
  "Strapi",
  "Strato",
  "Stripe",
  "SublimeVideo",
  "Subrion",
  "Sucuri",
  "Sulu",
  "SumoMe",
  "SunOS",
  "Supersized",
  "Svbtle",
  "Svelte",
  "SweetAlert",
  "SweetAlert2",
  "Swiftlet",
  "Swiftype",
  "Symfony",
  "Sympa",
  "Synology DiskStation",
  "SyntaxHighlighter",
  "TWiki",
  "tailwindcss",
  "TYPO3 CMS",
  "Taiga",
  "Tawk.to",
  "Tealeaf",
  "Tealium",
  "TeamCity",
  "Telescope",
  "TN Express Web",
  "Tessitura",
  "Tengine",
  "Textalk",
  "Textpattern CMS",
  "Thelia",
  "ThinkPHP",
  "Ticimax",
  "Tictail",
  "TiddlyWiki",
  "Tiki Wiki CMS Groupware",
  "Tilda",
  "Timeplot",
  "TinyMCE",
  "Titan",
  "TomatoCart",
  "TornadoServer",
  "TotalCode",
  "Trac",
  "TrackJs",
  "Transifex",
  "Translucide",
  "Tray",
  "Tumblr",
  "TweenMax",
  "Twilight CMS",
  "TwistPHP",
  "TwistedWeb",
  "Twitter",
  "Twitter Emoji (Twemoji)",
  "Twitter Flight",
  "Twitter typeahead.js",
  "TypePad",
  "Typecho",
  "Typekit",
  "UIKit",
  "UMI.CMS",
  "UNIX",
  "Ubercart",
  "Ubuntu",
  "UltraCart",
  "Umbraco",
  "Unbounce",
  "Underscore.js",
  "Usabilla",
  "user.com",
  "UserGuiding",
  "UserLike",
  "UserRules",
  "UserVoice",
  "Ushahidi",
  "VIVVO",
  "VP-ASP",
  "VTEX",
  "VTEX Integrated Store",
  "Vaadin",
  "Vanilla",
  "Varnish",
  "Venda",
  "Veoxa",
  "VideoJS",
  "VigLink",
  "Vigbo",
  "Vignette",
  "Vimeo",
  "VirtueMart",
  "Virtuoso",
  "Visual Website Optimizer",
  "VisualPath",
  "Volusion (V1)",
  "Volusion (V2)",
  "Vue.js",
  "Nuxt.js",
  "W3 Total Cache",
  "W3Counter",
  "WEBXPAY",
  "WHMCS",
  "WP Rocket",
  "WP Engine",
  "Warp",
  "Web2py",
  "Webflow",
  "WebGUI",
  "WebPublisher",
  "WebSite X5",
  "Webdev",
  "Webix",
  "Webmine",
  "Webs",
  "Websocket",
  "WebsPlanet",
  "Websale",
  "Website Creator",
  "WebsiteBaker",
  "Webtrekk",
  "Webtrends",
  "Weebly",
  "Weglot",
  "Webzi",
  "Whooshkaa",
  "Wikinggruppen",
  "WikkaWiki",
  "Windows CE",
  "Windows Server",
  "Wink",
  "Winstone Servlet Container",
  "Wix",
  "Wolf CMS",
  "Woltlab Community Framework",
  "WooCommerce",
  "Woopra",
  "Woosa",
  "WordPress",
  "WordPress Super Cache",
  "WordPress VIP",
  "Wowza Media Server",
  "X-Cart",
  "XAMPP",
  "XMB",
  "XOOPS",
  "XRegExp",
  "XWiki",
  "Xajax",
  "Xanario",
  "XenForo",
  "Xeora",
  "Xitami",
  "Xonic",
  "XpressEngine",
  "YUI",
  "YUI Doc",
  "YaBB",
  "Yahoo Advertising",
  "Yahoo! Ecommerce",
  "Yahoo! Tag Manager",
  "Yahoo! Web Analytics",
  "Yandex.Direct",
  "Yandex.Metrika",
  "Yaws",
  "Yieldlab",
  "Yii",
  "Yoast SEO",
  "WP-Statistics",
  "YouTrack",
  "YouTube",
  "iEXExchanger",
  "ZK",
  "ZURB Foundation",
  "Zabbix",
  "Zanox",
  "Zen Cart",
  "Zend",
  "Zendesk Chat",
  "Zendesk",
  "Zenfolio",
  "Zepto",
  "Zeuscart",
  "Zimbra",
  "Zinnia",
  "Zone.js",
  "Zope",
  "a-blog cms",
  "actionhero.js",
  "amCharts",
  "animate.css",
  "basket.js",
  "cPanel",
  "cgit",
  "comScore",
  "debut",
  "deepMiner",
  "e107",
  "eSyndiCat",
  "eZ Publish",
  "ef.js",
  "enduro.js",
  "git",
  "gitlist",
  "gitweb",
  "govCMS",
  "gunicorn",
  "iCongo",
  "iPresta",
  "iWeb",
  "ikiwiki",
  "imperia CMS",
  "io4 CMS",
  "ip-label",
  "jQTouch",
  "jQuery",
  "jQuery Migrate",
  "jQuery Mobile",
  "jQuery-pjax",
  "jQuery Sparklines",
  "jQuery UI",
  "jqPlot",
  "Kinsta",
  "libwww-perl-daemon",
  "lighttpd",
  "math.js",
  "mini_httpd",
  "mod_auth_pam",
  "mod_dav",
  "mod_fastcgi",
  "mod_jk",
  "mod_perl",
  "mod_python",
  "mod_rack",
  "mod_rails",
  "mod_ssl",
  "mod_wsgi",
  "nopCommerce",
  "openEngine",
  "osCSS",
  "osCommerce",
  "osTicket",
  "otrs",
  "ownCloud",
  "papaya CMS",
  "particles.js",
  "PhotoShelter",
  "phpAlbum",
  "phpBB",
  "phpCMS",
  "phpDocumentor",
  "phpMyAdmin",
  "phpPgAdmin",
  "phpSQLiteCMS",
  "phpliteadmin",
  "phpwind",
  "pirobase CMS",
  "prettyPhoto",
  "punBB",
  "reCAPTCHA",
  "sIFR",
  "Siteglide",
  "sNews",
  "script.aculo.us",
  "scrollreveal",
  "shine.js",
  "styled-components",
  "swift.engine",
  "three.js",
  "thttpd",
  "total.js",
  "uCoz",
  "uKnowva",
  "vBulletin",
  "vibecommerce",
  "Virgool",
  "shoperfa",
  "webEdition",
  "webpack",
  "wisyCMS",
  "parcel",
  "wpCache",
  "xCharts",
  "xtCommerce",
  "Yepcomm",
  "Halo",
  "Zipkin",
  "RX Web Server",
  "Tencent Waterproof Wall",
  "Saber",
  "GoCache",
  "Litespeed Cache",
  "nghttpx - HTTP/2 proxy",
  "Onshop",
  "pinoox",
  "Irroba",
  "Algolia",
  "ApostropheCMS",
  "Automattic",
  "CCV Shop",
  "DatoCMS",
  "decimal.js",
  "Duda",
  "EKM",
  "Ecwid",
  "Elm",
  "Elm-ui",
  "ExpertRec",
  "Fastspring",
  "GeneXus",
  "SAP Commerce Cloud",
  "Javadoc",
  "Loja Integrada",
  "Miva",
  "MyWebsite",
  "NetSuite",
  "Tebex",
  "Proximis Unified Commerce",
  "Proximis",
  "Riskified",
  "Simbel",
  "Strikingly",
  "Tamago",
  "GSAP",
  "Volusion",
  "NivCMS",
  "eZ Platform",
  "VuePress",
  "PageFly",
  "GOV.UK Frontend",
  "Varbase",
  "GrandNode",
  "Cafe24",
  "Live Story"
];
