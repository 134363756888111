import React from 'react';
import RequestError from '../../pages/api-documentation/request-error';

const ApiDocumentationLogsSanResults = () => {
  return (
    <div>
      <h4 className="info-title">To get monitoring logs use this get API.</h4>
      <h5>HTTP Request</h5>
      <pre className="pre">
        <p>
          <span className="btn-pre">GET</span>{' '}
          https://api.hexowatch.com/v2/app/services/v1/monitoring_logs/&#123;MONITORING_ID&#125;?key=&#123;YOUR_API_KEY&#125;
        </p>
      </pre>
      <h5>Parameters</h5>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">YOUR_API_KEY</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">
                {' '}
                to get YOUR_API_KEY visit hexowatch{' '}
                <a className="info-title" href="https://dash.hexowatch.com/hexowatch/settings" no-index>
                  settings{' '}
                </a>
                webhook section and copy your key
              </p>
            </td>
          </tr>
          <tr>
            <td className="td-fill">MONITORING_ID</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">
                To get all monitoring IDs go to "To get all your monitored URLs ids use this get API" section and follow
                the steps. After getting all the IDs provide the desired monitoring URL ID for this request.
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <h5>Request Body</h5>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className="td-fill">limit</td>
            <td>
              <p className="bold-text">
                number (optional <span className="info-title">default 10</span>)
              </p>
              <p className="normal-text">the limit of scan results</p>
            </td>
          </tr>
          <tr>
            <td className="td-fill">page</td>
            <td>
              <p className="bold-text">
                number (optional, <span className="info-title">default 1</span>)
              </p>
              <p className="normal-text">page pagination</p>
            </td>
          </tr>
          <tr>
            <td className="td-fill">Only_detected_changes</td>
            <td>
              <p className="bold-text">
                boolean (optional, <span className="info-title">default true</span>)
              </p>
              <p className="normal-text">if true, only return scan results with detected changes</p>
            </td>
          </tr>
        </tbody>
      </table>

      <h5>Example</h5>

      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"limit": 5,</p>
        <p className="mgl-25">"page": 1</p>
        <p className="mgl-25">"Only_detected_changes": true</p>
        <p>{'}'}</p>
      </pre>

      <h5>Response Body</h5>
      <p>
        if response is <strong className="info-title"> successful </strong>
        you will get
      </p>
      <div className="json-title-block">
        <span>JSON representation</span>
      </div>

      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": false,</p>
        <p className="mgl-25">"monitoring_id": "MONITORING_ID",</p>
        <p className="mgl-25">"tool": visualMonitoringTool,</p>
        <p className="mgl-25">"monitoring_results": [</p>
        <p className="mgl-50">{'{'}</p>
        <p className="mgl-75">"scan_result_id": "RESULT_ID",</p>
        <p className="mgl-75">"date": "2021-11-26T10:59:18.500Z",</p>
        <p className="mgl-75">"event_detected": true,</p>
        <p className="mgl-75">"percentage": 0.5</p>
        <p className="mgl-50">{'},'}</p>
        <p className="mgl-50">{'{'}</p>
        <p className="mgl-75">"scan_result_id": "RESULT_ID",</p>
        <p className="mgl-75">"date": "2021-11-26T10:58:50.225Z",</p>
        <p className="mgl-75">"event_detected": false,</p>
        <p className="mgl-75">"percentage": 0</p>
        <p className="mgl-50">{'},'}</p>
        <p className="mgl-25">]</p>
        <p>{'}'}</p>
      </pre>

      <RequestError />

      {/* ------------------------- get scan result  ------------------------------- */}

      <h4 className="info-title">To get scan result use this get API.</h4>
      <h5>HTTP Request transfering tool name via parameter</h5>
      <pre className="pre">
        <p>
          <span className="btn-pre">GET</span>{' '}
          https://api.hexowatch.com/v2/app/services/v1/scan_result/&#123;SCAN_RESULT_ID&#125;?key=&#123;YOUR_API_KEY&#125;&tool=&#123;TOOL&#125;
        </p>
      </pre>
      <h5>Parameters</h5>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">SCAN_RESULT_ID</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text"> go to the Monitoring Log page and use the Monitoring Log ID </p>
            </td>
          </tr>
          <tr>
            <td className="td-fill">YOUR_API_KEY</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">
                {' '}
                to get YOUR_API_KEY visit hexowatch{' '}
                <a className="info-title" href="https://dash.hexowatch.com/hexowatch/settings" no-index>
                  settings{' '}
                </a>
                webhook section and copy your key
              </p>
            </td>
          </tr>

          <tr>
            <td className="td-fill">TOOL</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">"visualMonitoringTool",</p>
              <p className="normal-text">"htmlElementMonitoringTool",</p>
              <p className="normal-text">"keywordTool",</p>
              <p className="normal-text">"techStackTool",</p>
              <p className="normal-text">"sourceCodeMonitoringTool",</p>
              <p className="normal-text">"contentMonitoringTool",</p>
              <p className="normal-text">"automaticAITool",</p>
              <p className="normal-text">"availabilityMonitoringTool",</p>
              <p className="normal-text">"domainWhoisTool",</p>
              <p className="normal-text">"sitemapTool",</p>
              <p className="normal-text">"apiMonitoringTool",</p>
              <p className="normal-text">"backlinkTool",</p>
              <p className="normal-text">"rssTool"</p>
            </td>
          </tr>
        </tbody>
      </table>

      <h5>HTTP Request transfering tool name via body</h5>
      <pre className="pre">
        <p>
          <span className="btn-pre">GET</span>{' '}
          https://api.hexowatch.com/v2/app/services/v1/scan_result/&#123;SCAN_RESULT_ID&#125;?key=&#123;YOUR_API_KEY&#125;
        </p>
      </pre>
      <h5>Parameters</h5>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">SCAN_RESULT_ID</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text"> go to the Monitoring Log page and use the Monitoring Log ID </p>
            </td>
          </tr>
          <tr>
            <td className="td-fill">YOUR_API_KEY</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">
                {' '}
                to get YOUR_API_KEY visit hexowatch{' '}
                <a className="info-title" href="https://dash.hexowatch.com/hexowatch/settings" no-index>
                  settings{' '}
                </a>
                webhook section and copy your key
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <h5>Request Body</h5>

      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className="td-fill">tool</td>
            <td>
              <p className="bold-text">
                string (required)<span className="info-title">*</span>
              </p>
              <p className="normal-text">you should provide monitoring tool name</p>
              <details>
                <summary className="my-1 info-title"> For example.</summary>
                "tool": "domainWhoisTool"
              </details>
            </td>
          </tr>
        </tbody>
      </table>

      <h5>Possible values for the fields</h5>
      <table>
        <thead>
          <tr>
            <th>fields</th>
            <th className="py-2">
              <span className="info-title info-sz"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">tool</td>
            <td>
              <p className="pad-0-marg-0">"visualMonitoringTool",</p>
              <p className="pad-0-marg-0">"htmlElementMonitoringTool",</p>
              <p className="pad-0-marg-0">"keywordTool",</p>
              <p className="pad-0-marg-0">"techStackTool",</p>
              <p className="pad-0-marg-0">"sourceCodeMonitoringTool",</p>
              <p className="pad-0-marg-0">"contentMonitoringTool",</p>
              <p className="pad-0-marg-0">"automaticAITool",</p>
              <p className="pad-0-marg-0">"availabilityMonitoringTool",</p>
              <p className="pad-0-marg-0">"domainWhoisTool",</p>
              <p className="pad-0-marg-0">"sitemapTool",</p>
              <p className="pad-0-marg-0">"apiMonitoringTool",</p>
              <p className="pad-0-marg-0">"backlinkTool",</p>
              <p className="pad-0-marg-0">"rssTool"</p>
            </td>
          </tr>
        </tbody>
      </table>

      <h5>Example</h5>

      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"tool": "domainWhoisTool"</p>
        <p>{'}'}</p>
      </pre>

      <h5>Response Body per tool</h5>
      <p>
        if response is <strong className="info-title"> successful </strong>
        you will get
      </p>

      <h5>visualMonitoringTool</h5>

      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": false,</p>
        <p className="mgl-25">"scanResult": {'{'}</p>

        <p className="mgl-50">"newData": {'{'}</p>
        <p className="mgl-75">"image": "IMAGE_LINK",</p>
        <p className="mgl-75">"imageHeight": 3565,</p>
        <p className="mgl-75">"imageWidth": 1890,</p>
        <p className="mgl-75">"viewport": {'{'}</p>
        <p className="mgl-100">"width": 1890,</p>
        <p className="mgl-100">"width": 1890,</p>
        <p className="mgl-75">{'}'}</p>
        <p className="mgl-50">{'},'}</p>

        <p className="mgl-50">"oldData": {'{'}</p>
        <p className="mgl-75">"image": "IMAGE_LINK",</p>
        <p className="mgl-75">"imageHeight": 3565,</p>
        <p className="mgl-75">"imageWidth": 1890,</p>
        <p className="mgl-75">"viewport": {'{'}</p>
        <p className="mgl-100">"width": 1890,</p>
        <p className="mgl-100">"width": 1890,</p>
        <p className="mgl-75">{'}'}</p>
        <p className="mgl-50">{'}'}</p>
        <p className="mgl-25">{'}'}</p>
        <p>{'}'}</p>
      </pre>

      <h5>htmlElementMonitoringTool</h5>

      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": false,</p>
        <p className="mgl-25">"scanResult": {'{'}</p>

        <p className="mgl-50">"newData": {'{'}</p>
        <p className="mgl-75">"element_data": [</p>
        <p className="mgl-100">{'{'}</p>
        <p className="mgl-125">"innerHTML": "TEXT",</p>
        <p className="mgl-125">"selectedNumbers": {},</p>
        <p className="mgl-125">"selectedElements": [],</p>
        <p className="mgl-125">"elementContent": "&lt;h2&gt;TEXT&lt;/h2&gt;"</p>
        <p className="mgl-100">{'}'}</p>
        <p className="mgl-75">]</p>
        <p className="mgl-75">"traffic": 1503218</p>
        <p className="mgl-50">{'},'}</p>

        <p className="mgl-50">"oldData": {'{'}</p>
        <p className="mgl-75">"element_data": [</p>
        <p className="mgl-100">{'{'}</p>
        <p className="mgl-125">"innerHTML": "TEXT",</p>
        <p className="mgl-125">"selectedNumbers": {},</p>
        <p className="mgl-125">"selectedElements": [],</p>
        <p className="mgl-125">"elementContent": "&lt;h2&gt;TEXT&lt;/h2&gt;"</p>
        <p className="mgl-100">{'}'}</p>
        <p className="mgl-75">]</p>
        <p className="mgl-75">"traffic": 1651842</p>
        <p className="mgl-50">{'}'}</p>
        <p className="mgl-25">{'}'}</p>
        <p>{'}'}</p>
      </pre>

      <h5>keywordTool</h5>

      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": false,</p>
        <p className="mgl-25">"scanResult": {'{'}</p>

        <p className="mgl-50">"newData": {'['}</p>
        <p className="mgl-75">{'{'}</p>
        <p className="mgl-100">"keyword": "keyword",</p>
        <p className="mgl-100">"found": true</p>
        <p className="mgl-75">{'}'}</p>
        <p className="mgl-50">{'],'}</p>

        <p className="mgl-50">"oldData": {'['}</p>
        <p className="mgl-75">{'{'}</p>
        <p className="mgl-100">"keyword": "keyword",</p>
        <p className="mgl-100">"found": false</p>
        <p className="mgl-75">{'}'}</p>
        <p className="mgl-50">{']'}</p>
        <p className="mgl-25">{'}'}</p>
        <p>{'}'}</p>
      </pre>

      <h5>techStackTool</h5>

      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": false,</p>
        <p className="mgl-25">"scanResult": {'{'}</p>

        <p className="mgl-50">"newData": {'{'}</p>
        <p className="mgl-75">"applications": [</p>
        <p className="mgl-100">{'{'}</p>
        <p className="mgl-125">"name": "NAME",</p>
        <p className="mgl-125">"confidence": 100,</p>
        <p className="mgl-125">"version": null,</p>
        <p className="mgl-125">"icon": "IMAGE.png",</p>
        <p className="mgl-125">"website": "example.com",</p>
        <p className="mgl-125">"categories": [</p>
        <p className="mgl-150">42</p>
        <p className="mgl-125">]</p>

        <p className="mgl-100">{'}'}</p>
        <p className="mgl-75">],</p>

        <p className="mgl-75">"specific_tech_stacks": [</p>
        <p className="mgl-100">{'{'}</p>
        <p className="mgl-125">"tech_stack_name": "3dCart",</p>
        <p className="mgl-125">"found": false</p>

        <p className="mgl-100">{'}'}</p>
        <p className="mgl-75">]</p>

        <p className="mgl-50">{'},'}</p>

        <p className="mgl-50">"oldData": {'{'}</p>
        <p className="mgl-75">"applications": [</p>
        <p className="mgl-100">{'{'}</p>
        <p className="mgl-125">"name": "NAME",</p>
        <p className="mgl-125">"confidence": 100,</p>
        <p className="mgl-125">"version": null,</p>
        <p className="mgl-125">"icon": "IMAGE.png",</p>
        <p className="mgl-125">"website": "example.com",</p>
        <p className="mgl-125">"categories": [</p>
        <p className="mgl-150">42</p>
        <p className="mgl-125">]</p>

        <p className="mgl-100">{'}'}</p>
        <p className="mgl-75">],</p>

        <p className="mgl-75">"specific_tech_stacks": [</p>
        <p className="mgl-100">{'{'}</p>
        <p className="mgl-125">"tech_stack_name": "3dCart",</p>
        <p className="mgl-125">"found": false</p>

        <p className="mgl-100">{'}'}</p>
        <p className="mgl-75">]</p>

        <p className="mgl-50">{'}'}</p>
        <p className="mgl-25">{'}'}</p>
        <p>{'}'}</p>
      </pre>

      <h5>sourceCodeMonitoringTool</h5>

      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": false,</p>
        <p className="mgl-25">"scanResult": {'{'}</p>

        <p className="mgl-50">"newData": {'{'}</p>
        <p className="mgl-75">"source_code_url": null,</p>
        <p className="mgl-75">"sections": [</p>
        <p className="mgl-100">{'{'}</p>
        <p className="mgl-125">
          "code": "&lt;meta content=\"width=device-width, initial-scale=1, maximum-scale=5\" name=\"viewport\"&gt;"
        </p>
        <p className="mgl-125">"found": true,</p>
        <p className="mgl-100">{'}'}</p>
        <p className="mgl-75">{'],'}</p>
        <p className="mgl-75">"traffic": 851553</p>
        <p className="mgl-50">{'},'}</p>

        <p className="mgl-50">"oldData": {'{'}</p>
        <p className="mgl-75">"source_code_url": null,</p>
        <p className="mgl-75">"sections": [</p>
        <p className="mgl-100">{'{'}</p>
        <p className="mgl-125">
          "code": "&lt;meta content=\"width=device-width, initial-scale=1, maximum-scale=5\" name=\"viewport\"&gt;"
        </p>
        <p className="mgl-125">"found": true,</p>
        <p className="mgl-100">{'}'}</p>
        <p className="mgl-75">{'],'}</p>
        <p className="mgl-75">"traffic": 857631</p>
        <p className="mgl-50">{'}'}</p>
        <p className="mgl-25">{'}'}</p>
        <p>{'}'}</p>
      </pre>

      <h5>contentMonitoringTool</h5>

      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": false,</p>
        <p className="mgl-25">"scanResult": {'{'}</p>

        <p className="mgl-50">"newData": {'{'}</p>
        <p className="mgl-75">"page_content_url": null,</p>
        <p className="mgl-75">"contents": [</p>
        <p className="mgl-100">{'{'}</p>
        <p className="mgl-125">"content": "content",</p>
        <p className="mgl-125">"found": true,</p>
        <p className="mgl-100">{'}'}</p>
        <p className="mgl-75">{']'}</p>
        <p className="mgl-50">{'},'}</p>

        <p className="mgl-50">"oldData": {'{'}</p>
        <p className="mgl-75">"page_content_url": null,</p>
        <p className="mgl-75">"contents": [</p>
        <p className="mgl-100">{'{'}</p>
        <p className="mgl-125">"content": "content",</p>
        <p className="mgl-125">"found": true,</p>
        <p className="mgl-100">{'}'}</p>
        <p className="mgl-75">{']'}</p>
        <p className="mgl-50">{'}'}</p>
        <p className="mgl-25">{'}'}</p>
        <p>{'}'}</p>
      </pre>

      <h5>automaticAITool</h5>

      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": false,</p>
        <p className="mgl-25">"scanResult": {'{'}</p>

        <p className="mgl-50">"visualMonitoringTool": SAME AS CORRESPONDING TOOL RESULT,</p>
        <p className="mgl-50">"techStackTool": SAME AS CORRESPONDING TOOL RESULT,</p>
        <p className="mgl-50">"sourceCodeMonitoringTool": SAME AS CORRESPONDING TOOL RESULT,</p>
        <p className="mgl-50">"contentMonitoringTool": SAME AS CORRESPONDING TOOL RESULT,</p>
        <p className="mgl-25">{'}'}</p>
        <p>{'}'}</p>
      </pre>

      <h5>availabilityMonitoringTool</h5>

      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": false,</p>
        <p className="mgl-25">"scanResult": {'{'}</p>

        <p className="mgl-50">"newData": {'{'}</p>
        <p className="mgl-75">"status": 200</p>
        <p className="mgl-50">{'},'}</p>

        <p className="mgl-50">"oldData": {'{'}</p>
        <p className="mgl-75">"status": 0</p>
        <p className="mgl-50">{'}'}</p>
        <p className="mgl-25">{'}'}</p>
        <p>{'}'}</p>
      </pre>

      <h5>domainWhoisTool</h5>

      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": false,</p>
        <p className="mgl-25">"scanResult": {'{'}</p>

        <p className="mgl-50">"newData": {'{'}</p>
        <p className="mgl-75">"url": "https://example.com/",</p>
        <p className="mgl-75">"domain": "example.com",</p>
        <p className="mgl-75">"status": "Registered",</p>
        <p className="mgl-75">"exists": true,</p>
        <p className="mgl-75">"expiresAt": "2023-01-19T06:23:59.000Z",</p>
        <p className="mgl-75">"expirationDays": 418,</p>
        <p className="mgl-75">"lastModified": "2021-02-07T10:33:30.000Z",</p>
        <p className="mgl-75">"registeredAt": "2019-01-19T06:23:59.000Z",</p>
        <p className="mgl-75">"fullText": "DOMAIN_FULL_INFORMATION",</p>
        <p className="mgl-75">"dnsData": "DNS_DATA"</p>
        <p className="mgl-50">{'}'}</p>

        <p className="mgl-50">"oldData": {'{'}</p>
        <p className="mgl-75">"url": "https://example.com/",</p>
        <p className="mgl-75">"domain": "example.com",</p>
        <p className="mgl-75">"status": "Registered",</p>
        <p className="mgl-75">"exists": true,</p>
        <p className="mgl-75">"expiresAt": "2023-01-19T06:23:59.000Z",</p>
        <p className="mgl-75">"expirationDays": 418,</p>
        <p className="mgl-75">"lastModified": "2021-02-07T10:33:30.000Z",</p>
        <p className="mgl-75">"registeredAt": "2019-01-19T06:23:59.000Z",</p>
        <p className="mgl-75">"fullText": "DOMAIN_FULL_INFORMATION",</p>
        <p className="mgl-75">"dnsData": "DNS_DATA"</p>
        <p className="mgl-50">{'}'}</p>
        <p className="mgl-25">{'}'}</p>
        <p>{'}'}</p>
      </pre>

      <h5>sitemapTool</h5>

      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": false,</p>
        <p className="mgl-25">"scanResult": {'{'}</p>

        <p className="mgl-50">"newData": {'{'}</p>
        <p className="mgl-75">"url_s": [],</p>
        <p className="mgl-75">"child_sitemap_urls": [</p>
        <p className="mgl-100">"http://example.com/sitemap-home.xml",</p>
        <p className="mgl-100">"http://example.com/sitemap-posttype-post.2021.xml",</p>
        <p className="mgl-100">"http://example.com/sitemap-posttype-post.2020.xml"</p>
        <p className="mgl-75">]</p>
        <p className="mgl-50">{'},'}</p>

        <p className="mgl-50">"oldData": {'{'}</p>
        <p className="mgl-75">"url_s": [],</p>
        <p className="mgl-75">"child_sitemap_urls": [</p>
        <p className="mgl-100">"http://example.com/sitemap-home.xml",</p>
        <p className="mgl-100">"http://example.com/sitemap-posttype-post.2021.xml",</p>
        <p className="mgl-75">]</p>
        <p className="mgl-50">{'}'}</p>
        <p className="mgl-25">{'}'}</p>
        <p>{'}'}</p>
      </pre>

      <h5>apiMonitoringTool</h5>

      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": false,</p>
        <p className="mgl-25">"scanResult": {'{'}</p>

        <p className="mgl-50">"newData": {'{'}</p>
        <p className="mgl-75">
          "response_body": "{'{'}"error\":false,\"monitored_urls\":[{'{'}
          \"id\":94343,\"address\":\"https://www.example.com/\",\"name\":\"https://www.example.com/\",\"paused\":true
          {'}'},{'{'}
          \"id\":94341,\"address\":\"example.com\",\"name\":\"example.com\",\"paused\":true
          {'}'},{'{'}
          \"id\":94340,\"address\":\"example.com\",\"name\":\"example.com\",\"paused\":true
          {'}'},{'{'}
          \"id\":94339,\"address\":\"example.com\",\"name\":\"example.com\",\"paused\":true
          {'}'},{'{'}
          \"id\":90530,\"address\":\"example.com\",\"name\":\"example.com\",\"paused\":true
          {'}'},{'{'}
          \"id\":90528,\"address\":\"example.com\",\"name\":\"example.com\",\"paused\":true
          {'}'}]{'}'}"
        </p>

        <p className="mgl-50">{'},'}</p>

        <p className="mgl-50">"oldData": {'{'}</p>
        <p className="mgl-75">
          "response_body": "{'{'}\"error\":false,\"monitored_urls\":[{'{'}
          \"id\":94343,\"address\":\"https://www.example.com/\",\"name\":\"https://www.example.com/\",\"paused\":true
          {'}'},{'{'}
          \"id\":94341,\"address\":\"example.com\",\"name\":\"example.com\",\"paused\":true
          {'}'},{'{'}
          \"id\":94340,\"address\":\"example.com\",\"name\":\"example.com\",\"paused\":true
          {'}'},{'{'}
          \"id\":94339,\"address\":\"example.com\",\"name\":\"example.com\",\"paused\":true
          {'}'},{'{'}
          \"id\":90530,\"address\":\"example.com\",\"name\":\"example.com\",\"paused\":true
          {'}'},{'{'}
          \"id\":90528,\"address\":\"example.com\",\"name\":\"example.com\",\"paused\":true
          {'}'}]{'}'}"
        </p>

        <p className="mgl-50">{'}'}</p>
        <p className="mgl-25">{'}'}</p>
        <p>{'}'}</p>
      </pre>

      <h5>backlinkTool</h5>

      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": false,</p>
        <p className="mgl-25">"scanResult": {'{'}</p>

        <p className="mgl-50">"newData": {'{'}</p>
        <p className="mgl-75">"hrefs": [</p>
        <p className="mgl-100">"href": "https://example.com/#top",</p>
        <p className="mgl-100">"rel": null,</p>
        <p className="mgl-100">"text": "Home",</p>
        <p className="mgl-100">"img": null</p>
        <p className="mgl-75">]</p>

        <p className="mgl-50">{'},'}</p>

        <p className="mgl-50">"oldData": {'{'}</p>
        <p className="mgl-75">"hrefs": [</p>
        <p className="mgl-100">"href": "https://example.com/#top",</p>
        <p className="mgl-100">"rel": null,</p>
        <p className="mgl-100">"text": "Home",</p>
        <p className="mgl-100">"img": null</p>
        <p className="mgl-75">]</p>

        <p className="mgl-50">{'}'}</p>
        <p className="mgl-25">{'}'}</p>
        <p>{'}'}</p>
      </pre>

      <h5>rssTool</h5>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"error": false,</p>
        <p className="mgl-25">"scanResult": {'{'}</p>
        <p className="mgl-50">
          "newData": "{'{'}\"rss\":{'{'}\"version\":[\"2.0\"]...."
        </p>
        <p className="mgl-50">
          "oldData": "{'{'}\"rss\":{'{'}\"version\":[\"2.0\"]...."
        </p>
        <p className="mgl-25">{'}'}</p>
        <p>{'}'}</p>
      </pre>

      <RequestError />
    </div>
  );
};

export default ApiDocumentationLogsSanResults;
